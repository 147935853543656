<template>
  <v-container class="fill-height mt-16">
    <v-row align="center" align-content="center" justify="center">
      <!-- <v-col cols="12" md="8">
        <v-card>
          <v-card-title
            ><v-icon class="mr-3">mdi-wrench-clock</v-icon>Geplante
            Wartungsarbeiten</v-card-title
          >
          <v-card-subtitle
            >Wir führen geplante Wartungsarbeiten durch. Mira One ist in Kürze
            wieder verfügbar.</v-card-subtitle
          >
        </v-card>
      </v-col> -->
      <v-col cols="12" md="8">
        <v-alert
          colored-border
          color="warning"
          border="left"
          icon="mdi-wrench-clock"
          elevation="2"
        >
          <div class="title mb-2">Wartungsarbeiten</div>
          <slot name="content">
            <div class="body-2">
              Wir führen Wartungsarbeiten durch. Mira One ist in Kürze wieder
              verfügbar.
            </div>
          </slot>
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "maintenance-view",
};
</script>
