<template>
  <div>
    <NavigationBar titel="Mitteilungen" isPreview></NavigationBar>
    <section>
      <template>
        <v-navigation-drawer v-model="drawer" bottom app clipped hide-overlay>
          <v-list nav>
            <v-list-item-group
              v-model="mode"
              active-class="primary white--text"
            >
              <v-list-item v-for="[icon, text] in links" :key="icon" link>
                <v-list-item-icon>
                  <v-icon>{{ icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>

            <v-divider></v-divider>
            <v-subheader>Filter</v-subheader>
            <v-list-item-group
              v-model="filterBy"
              active-class="primary white--text"
            >
              <v-list-item
                v-for="[icon, text] in filters"
                :key="text"
                link
                dense
              >
                <v-list-item-icon>
                  <v-icon>{{ icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>

            <v-divider></v-divider>
            <v-subheader>Organisationen</v-subheader>
            <v-list-item v-for="text in organizations" :key="text" link>
              <v-list-item-content>
                <v-list-item-title>{{ text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>

        <v-container class="py-8 px-6" fluid>
          <v-row>
            <v-col>
              <v-card>
                <v-card-title>
                  <v-chip-group v-model="listMode" color="primary" mandatory>
                    <v-chip value="all" color="grey lighten-4"> Alle</v-chip>
                    <v-chip value="unread" class="mr-4" color="grey lighten-4">
                      Ungelesen
                    </v-chip>
                  </v-chip-group>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn depressed v-bind="attrs" v-on="on" class="mr-4">
                        <span>
                          Gruppieren nach <strong>{{ groupBy }}</strong>
                        </span>
                        <v-icon right>mdi-menu-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list nav>
                      <v-list-item-group v-model="groupBy" mandatory>
                        <v-list-item link dense value="Datum">
                          <v-list-item-icon>
                            <v-icon dense>mdi-calendar-start</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Datum</v-list-item-title>
                        </v-list-item>
                        <v-list-item link dense value="Funktion">
                          <v-list-item-icon>
                            <v-icon dense>mdi-puzzle-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Funktion</v-list-item-title>
                        </v-list-item>
                        <v-list-item link dense value="Organisation">
                          <v-list-item-icon>
                            <v-icon dense>mdi-office-building</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Organisation</v-list-item-title>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                  <v-combobox
                    v-model="model"
                    :items="['a', 'b']"
                    outlined
                    label="Mitteilungen durchsuchen"
                    dense
                    prepend-inner-icon="mdi-magnify"
                    clearable
                    full-width
                    hide-details="auto"
                  ></v-combobox>
                </v-card-title>
              </v-card>
            </v-col>

            <v-col v-for="card in cards" :key="card" cols="12">
              <v-card>
                <v-subheader>{{ card }}</v-subheader>

                <v-list two-line>
                  <template v-for="n in 6">
                    <v-list-item :key="n">
                      <v-list-item-avatar color="grey darken-1">
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title>Message {{ n }}</v-list-item-title>

                        <v-list-item-subtitle>
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit. Nihil repellendus distinctio similique
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-divider
                      v-if="n !== 6"
                      :key="`divider-${n}`"
                      inset
                    ></v-divider>
                  </template>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";

export default {
  name: "notification-center",
  components: {
    NavigationBar,
  },
  data() {
    return {
      cards: ["Today", "Yesterday"],
      drawer: null,
      mode: "",
      listMode: "all",
      filterBy: "",
      groupBy: "Datum",
      links: [
        ["mdi-inbox", "Eingang"],
        ["mdi-bookmark-outline", "Gespeichert"],
        ["mdi-check", "Abgeschlossen"],
      ],
      filters: [
        ["mdi-calendar", "Veranstaltungen"],
        ["mdi-briefcase-edit-outline", "Berichte"],
        ["mdi-run-fast", "Abwesenheiten"],
      ],
      organizations: ["Einheit A", "Einheit B"],
    };
  },
};
</script>
