<template>
  <div class="not-found">
    <v-container class="text-left fill-height mt-16">
      <v-row align="top">
        <v-col>
          <h1 class="text-h3">404</h1>
          <h2 class="">Seite nicht gefunden</h2>
          <p class="subtitle-1">
            Überprüfe die URL in der Adressleiste des Browsers und versuche es
            erneut.
          </p>
          <v-btn
            :to="{ name: 'organization-list' }"
            link
            color="primary"
            depressed
            ><v-icon left>mdi-chevron-left</v-icon>Zurück zur
            Organisationsliste</v-btn
          >
          <v-btn
            class="ml-4"
            color="primary"
            outlined
            href="mailto:support@mira-one.com"
          >
            Support kontaktieren
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "not-found",
};
</script>
