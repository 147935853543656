<template>
  <div class="module-not-activated">
    <v-container class="my-5">
      <h1 class="title">Modul nicht aktiviert</h1>
      <p class="subtitle-1">Die gewünschte Seite ist nicht abrufbar, da die entsprechende Erweiterung nicht aktiviert wurde. Wende Dich an Deinen Administrator, um diese Erweiterung zu laden.</p>
    </v-container>  
  </div>
</template>

<script>

export default {
  name: 'not-found',
  components: {

  }
}
</script>
