<template>
  <div class="">
    <v-container class="my-5">
      <h1 class="title">Fehler: {{ this.$route.query.code }}</h1>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "error",
  created() {
    console.log("Error: ", this.$route.query.code);
  },
};
</script>
